$color-orange: #ff5e0d;
$color-grey: #2f2c2c;
$font: "DM Sans";
$color-white: #ffffff;

.switch {
  position: absolute;
  right: -25px;
  top: 5px;
  background: $color-white;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 4px rgb(0 0 0 / 20%);
  cursor: pointer;
  .icon {
    font-size: 1.4rem;
  }
}
@media screen and (max-width: 980px) {
  .switch {
    right: 10px;
    top: 40px;
    background: $color-white;
  }
}
