.subscribeSection {
  display: flex;
  border: 1px solid #cccccc;
  border-radius: 5px;
  padding: 40px 40px 60px;

  .info {
    width: 50%;
  }

  .subEmail {
    width: 50%;
    margin-left: 50px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .subscribeInput {
    @apply px-4;
    border: 1px solid #cccccc;
    height: 50px;
    margin: 0;
    min-width: 250px;
    width: 350px;
    max-width: 350px;
    border-radius: 8px 0px 0px 8px;
    color: #424950;
    font-size: inherit;
  }

  .subscribeInput::placeholder {
    color: #424950;
    font-weight: 500;
    font-size: inherit;
  }

  .subscribeButton {
    padding: 0 30px 0 30px;
    height: 50px;
    background-color: #ff5e0d;
    border-radius: 0px 8px 8px 0px;
    color: #ffffff;
    font-weight: 600;
    font-size: 1.1rem;
  }
}

@media screen and (max-width: 1070px) {
  .subscribeSection {
    flex-direction: column;
    .info {
      width: 100%;
    }

    .subEmail {
      width: 100%;
      margin-left: 0px;
      margin-top: 20px;
      justify-content: center;
    }
    .subscribeInput {
      min-width: initial;
      width: 100%;
      max-width: initial;
    }
  }
}

@media screen and (max-width: 500px) {
  .subscribeSection {
    padding: 20px 40px 40px;
    .subEmail {
      flex-direction: column;
    }
    .subscribeInput {
      border-radius: 8px;
    }
    .subscribeButton {
      width: 100%;
      margin-top: 20px;
      border-radius: 8px;
    }
  }
}
