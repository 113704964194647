$color-orange: #ff5e0d;
$color-grey: #2f2c2c;
$font: "DM Sans";
$color-white: #ffffff;

// flight booking engine in the home page
.flightBooking {
  .travelerName:hover {
    cursor: pointer;
    color: $color-orange;
  }

  .options {
    display: flex;
    justify-content: space-between;

    ul.ticketOptions {
      list-style-type: none;
    }
    .tripType {
      display: flex;
      font-weight: 600;
      li {
        margin-right: 45px;
        cursor: pointer;
      }
      li.active {
        color: $color-orange;
      }
    }
    .ticketType {
      display: flex;
      position: relative;
      font-weight: 600;
      p {
        display: flex;
        align-items: center;
        .icons {
          margin-left: 5px;
          transform: rotateZ(0deg);
          transition: transform 300ms ease-in-out;
        }
        .rotate {
          transform: rotateZ(180deg);
        }
      }
      ul.travelerNumbers {
        position: absolute;
        left: -60px;
        padding: 20px 20px;
        border-radius: 6px;
        box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.15);
        background-color: #ffffff;
        z-index: 2;
        li {
          height: 50px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 10px;
          cursor: auto;
          .left {
            span {
              display: block;
              margin-right: 40px;
            }
            span:last-child {
              font-size: 0.75em;
              @apply text-greyDark;
              font-weight: normal;
            }
          }
          .right {
            display: flex;
            span {
              display: flex;
              align-items: center;
              justify-content: center;
            }

            .iconBorder {
              border: 2px solid;
              border-radius: 5px;
              cursor: pointer;
              @apply border-greyDark text-greyDark;
              .iconsInput {
                margin: 7px;
                font-size: 0.85em;
              }
              &:active {
                @apply bg-greyLight;
              }
              &:hover {
                background-color: $color-orange;
                color: $color-white;
                border-color: $color-orange;
              }
            }
            .personNo {
              width: 35px;
            }
          }
        }
      }

      .btnWrapper {
        text-align: right;
        button {
          background-color: #ff5e0d;
          height: 30px;
          width: 70px;
          color: white;
          margin-top: 7px;
          border-radius: 5px;
        }
      }

      .ticketClass {
        p {
          margin-left: 45px;
        }
        li {
          width: 170px;
          padding: 5px 10px;
          border-radius: 6px;
        }
        li:hover {
          background-color: #ffece2;
          cursor: pointer;
        }

        .ticketName {
          cursor: pointer;
          &:hover {
            color: $color-orange;
          }
        }

        .ticketOptions {
          right: -30px;
        }
      }
    }
    .ticketClass {
      position: relative;
      p {
        display: flex;
        align-items: center;
        .icons {
          margin-left: 5px;
          transform: rotateZ(0deg);
          transition: transform 300ms ease-in-out;
        }
        .rotate {
          transform: rotateZ(180deg);
        }
      }
      ul {
        position: absolute;
        right: 0;
        padding: 20px 20px;
        border-radius: 6px;
        box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.15);
        background-color: #ffffff;
        z-index: 2;
        li {
          margin-bottom: 5px;
        }
      }
    }
  }

  .flightEngine {
    .tripCard {
      display: flex;
      margin-top: 15px;
      & > div {
        margin-right: 10px;
      }
      & > div:last-child {
        margin-right: 0px;
      }
    }
    .tripCardTop,
    .tripCardBottom {
      display: none;
    }
    .button {
      button {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid $color-orange;
        background-color: $color-orange;
        color: white;
        border-radius: 6px;
        height: 50px;
        min-width: 60px;
        width: 60px;
        max-width: 60px;
      }
      .disabled {
        background-color: #ff5e0d;
        cursor: not-allowed;
      }
    }
    .buttonSmall {
      cursor: pointer;
      display: none;
    }

    .departure,
    .arrival,
    .date {
      border-radius: 6px;
      min-width: calc((100% - 90px) / 3);
      width: calc((100% - 90px) / 3);
      max-width: calc((100% - 90px) / 3);

      label {
        border: 1px solid $color-grey;
        flex-grow: 1;
        height: 50px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #fff;
        border-radius: 6px;
        position: relative;
        .inputIcon {
          min-width: 20px;
          width: 20px;
          max-width: 20px;
          height: 100%;
          margin: 0 10px;
          margin-left: 20px;
          margin-top: -1px;
        }
        .input,
        input {
          width: 100%;

          border-radius: 6px;
          font-size: 0.9rem;
          font-weight: 500;
        }
        input::placeholder {
          color: $color-grey;
          padding-left: 5px;

          font-weight: 500;
          font-size: 0.9rem;
        }

        input:focus {
          outline: none;
          border-color: $color-grey;
        }
      }
    }
    .departure,
    .arrival {
      position: relative;
    }
    .date {
      label {
        * {
          border-radius: 5px;
        }
        .inputIcon {
          min-width: 15px;
          width: 15px;
          max-width: 15px;
          height: 100%;
          margin: 0 6px;
          margin-left: 20px;
        }
        .input {
          display: flex;
          align-items: center;
        }
      }
    }

    .citySuggestions {
      overflow-y: scroll;
      height: 260px;
      position: absolute;
      background-color: #fff;
      top: calc(100% + 10px);
      padding: 20px;
      border-radius: 6px;
      box-shadow: 0 4px 25px rgb(0 0 0 / 15%);
      z-index: 2;
      cursor: default;
      width: 100%;

      min-height: 100px;
      li {
        padding: 4px 8px;
        border-radius: 7px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
        .left {
          span {
            display: block;
          }
          .top {
            font-weight: 700;
          }
          .bottom {
            font-weight: 400;
            font-size: 12px;
          }
        }
        .right {
          border: 2px solid;
          @apply border-tramangoOrange;
          padding: 7px 14px;
          border-radius: 5px;
          margin-left: 20px;
          width: 70px;
          text-align: center;
          max-width: 70px ;
          min-width: 70px;
          flex-shrink: 0;
        }
        transition: color 200ms ease-in-out, background 200ms ease-in-out;
      }
      li:hover {
        color: black;
        @apply bg-tramangolightOrange;
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .flightBooking {
    .options {
      flex-direction: column;
      .tripType {
        li {
          margin-bottom: 10px;
          margin-right: 15px;
        }
      }
      .ticketType {
        justify-content: flex-start;
        .ticketClass {
          p {
            margin-left: 15px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 980px) {
  .flightBooking {
    .travelerNumbers {
      left: 0 !important;
    }
    .flightEngine {
      .tripCard {
        flex-direction: column;
        margin-bottom: 10px;
        & > div {
          margin-bottom: 20px;
        }
        .date {
          margin-right: 0px;
          margin-bottom: 10px;
        }
      }

      .tripCardTop {
        display: flex;
        justify-content: space-between;
        .removeBtn {
          font-weight: bold;
          transition: color 300ms ease-in-out;
          cursor: pointer;
        }
        .removeBtn:hover {
          color: $color-orange;
        }
      }
      .tripCardBottom {
        display: block;
        margin-left: auto;
        .addBtn {
          color: $color-orange;
          font-weight: bold;
          cursor: pointer;
        }
      }
      .departure,
      .arrival,
      .date {
        min-width: 100%;
        width: 100%;
        max-width: 100%;
      }
      .date {
        margin-bottom: 0;
      }
      .button {
        display: none;
      }
      .buttonSmall {
        margin-top: 20px;
        border-radius: 6px;
        height: 50px;
        min-width: 100%;
        width: 100%;
        max-width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid $color-orange;
        background-color: $color-orange;
        color: white;
      }
    }
  }
}

// .errorMessage {
//   @apply text-error;
//   margin-top: 2px;
//   margin-bottom: 0;
// }
