.customerSupport {
    margin-top: 20px;
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr 1fr 1fr;
  
    .link {
      @apply flex items-center;
      border: 1px solid #cccccc;
      border-radius: 6px;
      padding: 15px 20px;
      min-height: 150px;
      height: 100%;
      min-width: 250px;
      width: 100%;
      
    }
    img {
      @apply  shrink-0 sm:min-w-[100px] min-w-[70px]
    }
  
    .info {
      margin-left: 20px;
    }
  
    .info p {
      color: rgba(66, 73, 80, 0.75);
    }
  }
  
  @media screen and (max-width: 1100px) {
    .customerSupport {
      grid-template-columns: 1fr 1fr;
      gap: 20px;
      .link {
        width: 100%;
        max-width: 100%;
      }
      .info {
        margin-left: 10px;
      }
    }
  }
  
  @media screen and (max-width: 600px) {
    .customerSupport {
      grid-template-columns: 1fr;
    }
  }