.error {
  border-radius: 10px;
  padding: 20px;
  background: #ffffff;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  margin: auto;
  text-align: center;
  h3 {
    margin-top: 15px;
  }
  p {
    margin-top: 15px;
  }
  button {
    margin-top: 20px;
    @apply bg-tramangoOrange text-white;
    cursor: pointer;
    border-radius: 6px;
    height: 50px;
    width: 100%;
    max-width: 300px;
    margin-bottom: 20px;
  }
}
