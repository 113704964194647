.modal {
  z-index: 10000;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.419);
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 30px;
  .popUp {
    width: clamp(250px, 100%, 600px);
    height: clamp(400px, 50%, calc(100% - 80px));
    overflow: auto;
    @apply bg-white;
    padding: 20px;
    border-radius: 20px;
  }
}
