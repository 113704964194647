.tripCard {
  width: 250px;
  cursor: pointer;
  .locationImage {
    border-radius: 10px 10px 0px 0px;
    width: 250px;
    height: 170px;
  }

  .info {
    padding: 10px;
    margin-top: -2px;
    border: 1px solid #eee;
    box-shadow: 0px 2px 2px #eee;
    border-radius: 0 0 10px 10px;
    background-color: #fff;
  }

  .title {
    font-size: 1rem;
  }
  .airline {
    width: 20px;
    height: 20px;
    margin-right: 5px;
  }

  .additionalInfo {
    margin-bottom: 0;
  }

  .price {
    float: right;
    font-weight: bold;
    color: #2f2c2c;
  }

  .clear {
    clear: both;
    margin: 0;
    padding: 0;
  }
}

.tripCardSkeleton {
  width: 250px;

  .locationImage {
    border-radius: 10px 10px 0px 0px;
    width: 250px;
    height: 170px;
  }

  .info {
    padding: 10px;
    margin-top: -2px;
    border: 1px solid #eee;
    box-shadow: 0px 2px 2px #eee;
    border-radius: 10px 10px 10px 10px;
    background-color: #fff;
    height: 300px;
    .box {
      height: 150px;
      width: 220px;
      animation: skeleton-loading 1s linear infinite alternate;
      border-radius: 10px;
    }
    .line {
      height: 10px;
      width: 220px;
      animation: skeleton-loading 1s linear infinite alternate;
      margin-top: 10px;
      border-radius: 10px;
    }
  }

  .title {
    font-size: 1rem;
  }
  .airline {
    width: 20px;
    height: 20px;
    margin-right: 5px;
  }

  .additionalInfo {
    margin-bottom: 0;
  }

  .price {
    float: right;
    font-weight: bold;
    color: #2f2c2c;
  }

  .clear {
    clear: both;
    margin: 0;
    padding: 0;
  }
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 70%);
  }

  100% {
    background-color: hsl(200, 20%, 95%);
  }
}
