.flightTravelDiscount {
  width: 100%;
  z-index: 0;
  img {
    z-index: 0;
    border-radius: 5px;
    width: 100%;
    height: auto;
    max-height: 300px;
    object-fit: cover;
  }
}
