.seatmap {
  .container {
    border: 1px solid #ccc;
    display: flex;
    justify-content: center;
    height: 100%;
    z-index: 10000;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.419);
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 30px;
    width: 100vw;
    height: 100vh;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #ccc;
    display: flex;
    justify-content: center;
    height: 100%;
    z-index: 10000;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.419);

    .popUp {
      // width: clamp(250px, 100%, 600px);
      // height: clamp(400px, 100%, calc(100% - 80px));
      width: 75%;
      height: 90%;
      display: flex;
      justify-content: center;
      overflow: auto;
      @apply bg-white;
      padding: 5px;

      border-radius: 7px;

      .seatMapContainer {
        display: flex;
        flex-direction: row-reverse;
        gap: 20px;
        width: 100%;
        justify-content: space-between;
        position: relative;
        overflow: auto;
        padding-bottom: 50px;
      }

      .planeSection {
        margin-top: 30px;
        text-align: center;
        width: 60%;

        display: flex;
        height: max-content;
        min-width: 400px;
        flex-direction: column;
        align-items: center;
        font-weight: 700;
        text-transform: capitalize;
        h2 {
          font-size: medium;
        }
      }
      .infoSection {
        margin: 20px;
        margin-top: 5em;
        height: max-content;
        padding: 20px;
        min-width: 300px;
        width: calc(35% - 40px);
        border-radius: 7px;
        box-shadow: 0 35px 60px -15px rgba(0, 0, 0, 0.3);
        position: sticky;
        top: 30px;

        p {
          font-size: small;
        }

        h2 {
          font-size: medium;
          margin-bottom: 20px;
        }
        .legend {
          display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 10px;

          .legendItem {
            display: flex;
            gap: 5px;
          }
        }

        .closeButton {
          margin: 25px auto;
          margin-bottom: 0;
          padding: 5px 25px;
          background-color: #ff5e0d;
          float: right;
          border-radius: 3px;
          color: #ffffff;
        }
      }
    }
  }
}
@media (max-width: 1300px) {
  .seatmap .container .popUp {
    width: 90%;
    height: 90%;
    padding: 20px;
  }
}

@media (max-width: 992px) {
  .seatMapContainer {
    display: flex;
    flex-direction: row-reverse;
    gap: 20px;
    width: 100%;
    justify-content: center !important;
    flex-wrap: wrap;
  }

  .infoSection {
    position: unset !important;
    margin-top: 30px !important;
  }
}

@media (max-width: 500px) {
  .seatmap .container .popUp {
    width: 100% !important;
    padding: 0px;
  }
}
