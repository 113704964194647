.blog {
  margin-top: 20px;
  display: flex;
  align-items: stretch;

  .icon {
    margin-left: 5px;
    font-size: 0.8rem;
  }

  .title {
    position: absolute;
    bottom: 0;
    padding: 10px;
    width: 100%;
    height: 100%;
    margin: 0;
    border-radius: 10px 10px 0 0;
    display: flex;
    align-items: flex-end;
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7));
    color: #fff;
  }

  .postSection1,
  .postSection2 {
    margin-right: 20px;
  }

  .postSection1 {
    width: 45%;
    height: 420px;
    border-radius: 10px;

    .imageWrapper {
      position: relative;
      width: 100%;
      height: 360px;
      border-radius: 10px;
      object-fit: cover;
    }
    .link {
      height: 60px;
      font-weight: bolder;
    }
  }

  .postSection2 {
    width: 30%;
    height: 420px;
    .imageWrapper {
      position: relative;
      width: 100%;
      height: 360px;
      border-radius: 10px;
      object-fit: fill;
    }
    .link {
      height: 60px;
      font-weight: bolder;
    }
  }

  .postSection3 {
    width: 25%;
    height: 420px;
    .card {
      height: calc(50% - 10px);
      &:first-child {
        margin-bottom: 20px;
      }
    }
    .imageWrapper {
      position: relative;
      width: 100%;
      height: 140px;
      border-radius: 10px;
      object-fit: cover;
    }
    .link {
      min-height: 50px !important;
      font-weight: bolder;
    }
  }

  .imageWrapper {
    img {
      object-fit: cover;
    }
  }

  .card {
    background: #ffff;
    border-radius: 10px;
    cursor: pointer;
    border: 1px solid #eee;
    .link {
      transition: 300ms ease-in-out;
    }
    &:hover {
      .link {
        padding-left: 20px;
        color: #ff5e0d;
      }
    }
    img {
      width: 100%;
      height: 100%;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
    .link {
      text-align: left;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 10px;
      margin-bottom: 0;

      .icons {
        margin-left: 10px;
      }
    }
  }

  @media screen and (max-width: 960px) {
    .postSection1 {
      width: 40%;
    }
    .postSection2 {
      width: 35%;
    }
  }

  @media screen and (max-width: 860px) {
    flex-wrap: wrap;
    .postSection1 {
      width: calc(50% - 10px);
      height: 300px;
      margin-right: 20px;
      margin-bottom: 20px;
      .imageWrapper {
        height: 250px;
      }
    }
    .postSection2 {
      width: calc(50% - 10px);
      height: 300px;
      margin-right: 0;
      .imageWrapper {
        height: 250px;
      }
    }
    .postSection3 {
      width: 100%;
      display: flex;
      height: 100%;
      .card {
        height: 300px;
        width: 100%;
        &:first-child {
          margin-right: 20px;
        }
        .imageWrapper {
          height: 250px;
        }
      }
    }
  }

  @media screen and (max-width: 500px) {
    flex-wrap: wrap;
    .postSection1 {
      width: 100%;
      height: 250px;
      margin-right: 0px;
      .imageWrapper {
        height: 200px;
      }
    }
    .postSection2 {
      width: 100%;
      height: 250px;
      margin-right: 0;
      margin-bottom: 20px;
      .imageWrapper {
        height: 200px;
      }
    }
    .postSection3 {
      height: 100%;
      margin-right: 0;
      margin-bottom: 20px;
      flex-wrap: wrap;
      .card:first-child {
        margin-right: 0px;
      }
      .imageWrapper {
        height: 200px;
      }
    }
  }
}
